import "./not-found.css";

function NotFound() {
  return (
    <div className="not__found">
      <h1>Oops! Page not found</h1>
    </div>
  );
}

export default NotFound;
