import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/layout/navbar/Navbar";
import "./user-loan.css";
import image1 from "../../../assets/icons/1134729_119243-OQ6MPG-604.jpg";
import { HiLightBulb } from "react-icons/hi";

function UserLoan() {
  const navigate = useNavigate();
  return (
    <>
      <div className="user__loan">
        <div className="more_header">
          <h1>Loan</h1>
        </div>
        <div className="user__loan__body">
          <img src={image1} alt="money" />
          <h1>Get quality loans from the Bank of Industry!</h1>
          <p>Up to #25,000,000 available for as low as 3.5% Interest rate</p>
          <div className="user__loan__body__info">
            <span>
              <p>Macro loans are secured by tier 1 collaterals</p>
              <h3>Learn more</h3>
            </span>
            <HiLightBulb className="user__loan__body__info__icon" />
          </div>
          <button onClick={() => navigate("/user/apply")}>
            Apply for loan
          </button>
        </div>
      </div>
      <Navbar number={2} />
    </>
  );
}

export default UserLoan;
