import "./header.css";
import logo from "../../../assets/icons/OIP.jpeg";
import { CiMenuFries } from "react-icons/ci";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const [active, setActive] = useState(false);
  const handleSidebar = () => {
    setActive(!active);
  };

  return (
    <div className="header">
      <img src={logo} alt="logo" className="header__logo" />
      <ul className="header__nav">
        <li>Home</li>
        <li>How it works</li>
        <li>Blog</li>
        <li>Contact us</li>
      </ul>
      <span className="header__acc">
        <button onClick={() => navigate("/signin")}>Login</button>
        <button onClick={() => navigate("/signup")}>Sign up</button>
        <CiMenuFries className={`header__menu`} onClick={handleSidebar} />
      </span>

      {/* SIDE BAR ELEMENTS */}
      <div className={`sidebar${active ? "--active" : ""}`}>
        <span className="sidebar__header">
          <img src={logo} alt="logo" className="header__logo" />
          <button onClick={handleSidebar}>Close</button>
        </span>
        <ul className="sidebar__nav">
          <li>Home</li>
          <li>Who we are</li>
          <li>What we do</li>
          <li>Contact us</li>
        </ul>
        <span className="sidebar__acc">
          <button onClick={() => navigate("/signin")}>Login</button>
          <button onClick={() => navigate("/signup")}>Sign up</button>
        </span>
      </div>
    </div>
  );
}

export default Header;
