import { useState } from "react";
import Navbar from "../../../components/layout/navbar/Navbar";
import "./kyc.css";
import { useNavigate } from "react-router-dom";

function KYC() {
  const navigate = useNavigate();
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
    select3: false,
  });

  const [incomeType, setIncomeType] = useState("");

  const [incompleteFields, setIncompleteFields] = useState(false);

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    state: "",
    email: "",
    education: "",
    program: "",
    id: "",
    incomeRange: "",
    category: "",
    institutionEmployerID: "",
    linkSalaryAccount: "",
    authorizeDirectPay: "",
    backupCollectionAccount: "",
    completeGuarantor: "",
    businessType: "",
    cacBusinessLookup: "",
    turnover: "",
    linkBusinessAccount: "",
    useOfFunds: "",
    tenor: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIncompleteFields(false);

    // Validate form inputs
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      !formData.address ||
      !formData.state ||
      !formData.education ||
      !formData.program ||
      !formData.id
    ) {
      setIncompleteFields(true);
      return;
    }

    try {
      setFormStatus({ ...formStatus, submitting: true });

      //handle form submission logic here

      setFormStatus({ submitting: false, submitted: true });
      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        address: "",
        state: "",
        education: "",
        program: "",
        id: "",
        incomeRange: "",
        category: "",
        institutionEmployerID: "",
        linkSalaryAccount: "",
        authorizeDirectPay: "",
        backupCollectionAccount: "",
        completeGuarantor: "",
        businessType: "",
        cacBusinessLookup: "",
        turnover: "",
        linkBusinessAccount: "",
        useOfFunds: "",
        tenor: "",
      });

      setTimeout(() => {
        setFormStatus({ ...formStatus, submitted: false });
      }, 10000);
    } catch (error) {
      console.error("Error sending email:", error);
      setFormStatus({ submitting: false, submitted: false });
      // Handle error scenario
    }
  };

  return (
    <>
      <Navbar />
      <div className="kyc">
        <div className="more_header">
          <h1>KYC</h1>
        </div>
        <div className="kyc__body">
          <section className="kyc__body__section__one">
            <div className="kyc__body__section__one__select__div">
              <button
                className={
                  select.select1
                    ? "kyc__body__section__one__select selected"
                    : "kyc__body__section__one__select"
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: true,
                    select2: false,
                    select3: false,
                  }))
                }
              >
                Personal
              </button>
              <button
                className={
                  select.select2
                    ? "kyc__body__section__one__select selected"
                    : "kyc__body__section__one__select"
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: false,
                    select2: true,
                    select3: false,
                  }))
                }
              >
                Employment Information
              </button>
              <button
                className={
                  select.select3
                    ? "kyc__body__section__one__select selected"
                    : "kyc__body__section__one__select"
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: false,
                    select2: false,
                    select3: true,
                  }))
                }
              >
                Next of kin
              </button>
            </div>
          </section>
          {select.select1 && (
            <section className="kyc__body__section__two">
              <form className="kyc__form" onSubmit={handleSubmit}>
                <div className="modal__apply__content__input__wrap">
                  <span>
                    <label className="modal__apply__label">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">Phone Number</label>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">Date of Birth</label>
                    <input
                      type="date"
                      name="dob"
                      placeholder="DOB"
                      value={formData.dob}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">
                      Bank Verification Number
                    </label>
                    <input
                      type="number"
                      name="bvn"
                      placeholder="BVN"
                      value={formData.bvn}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">
                      National Identification Number
                    </label>
                    <input
                      type="number"
                      name="nin"
                      placeholder="NIN"
                      value={formData.nin}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                </div>

                <button type="submit">Save changes</button>
              </form>
            </section>
          )}
          {select.select2 && (
            <section className="kyc__body__section__two">
              <h3>Employment data</h3>
              <form className="kyc__form" onSubmit={handleSubmit}>
                <div className="modal__apply__content__input__wrap">
                  <span>
                    <h3 className="modal__apply__label">Income type</h3>
                    <select
                      required
                      name="incomeType"
                      id="incomeType"
                      value={formData.incomeType}
                      onChange={(e) => {
                        handleChange(e);
                        setIncomeType(e.target.value);
                      }}
                      className="modal__apply__input"
                    >
                      <option value={null}>--</option>
                      <option value="selfEmployed">
                        Entrepreneur/Self Employed
                      </option>
                      <option value="employed">Employed/Salary Earner</option>
                      <option value="unemployed">Unemployed</option>
                    </select>
                  </span>
                  <span>
                    <h3 className="modal__apply__label">Category</h3>
                    <select
                      required
                      name="category"
                      id="category"
                      value={formData.category}
                      onChange={handleChange}
                      className="modal__apply__input"
                    >
                      <option value={null}>--</option>
                      <option value="Automobile">Automobile</option>
                      <option value="Banking">Banking</option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Construction">Construction</option>
                      <option value="Education">Education</option>
                      <option value="Energy">Energy</option>
                      <option value="Entertainment">Entertainment</option>
                      <option value="Financial Services">
                        Financial Services
                      </option>
                      <option value="Food and Beverage">
                        Food and Beverage
                      </option>
                      <option value="Healthcare">Healthcare</option>
                      <option value="Hospitality">Hospitality</option>
                    </select>
                  </span>

                  <span>
                    <h3 className="modal__apply__label">Income Range</h3>
                    <select
                      required
                      name="incomeRange"
                      id="incomeRange"
                      value={formData.incomeRange}
                      onChange={handleChange}
                      className="modal__apply__input"
                    >
                      <option value={null}>--</option>
                      <option value="0 - 50,000">0 - 50,000</option>
                      <option value="50,000 - 100,000">50,000 - 100,000</option>
                      <option value="100,000 - 200,000">
                        100,000 - 200,000
                      </option>
                      <option value="200,000 - 500,000">
                        200,000 - 500,000
                      </option>
                      <option value="500,000 - 1,000,000">
                        500,000 - 1,000,000
                      </option>
                      <option value="1,000,000 and above">
                        1,000,000 and above
                      </option>
                    </select>
                  </span>
                </div>

                {incomeType === "employed" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "start",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "700",
                        margin: "10px 0",
                        color: "#046307",
                      }}
                    >
                      Employed/Salary Earner details
                    </h3>
                    <div className="modal__apply__content__input__wrap">
                      <span>
                        <h3 className="modal__apply__label">
                          Institution/Employer/ID
                        </h3>
                        <input
                          required
                          name="institutionEmployerID"
                          placeholder="Name/Company ID"
                          value={formData.institutionEmployerID}
                          onChange={handleChange}
                          disabled={formStatus.submitting}
                        />
                      </span>
                      <span>
                        <h3 className="modal__apply__label">
                          Link Salary Account
                        </h3>
                        <select
                          required
                          name="linkSalaryAccount"
                          id="linkSalaryAccount"
                          value={formData.linkSalaryAccount}
                          onChange={handleChange}
                          className="modal__apply__input"
                        >
                          <option value={null}>--</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </span>
                      <span>
                        <h3 className="modal__apply__label">
                          Authorize Direct Pay
                        </h3>
                        <select
                          required
                          name="authorizeDirectPay"
                          id="authorizeDirectPay"
                          value={formData.authorizeDirectPay}
                          onChange={handleChange}
                          className="modal__apply__input"
                        >
                          <option value={null}>--</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </span>
                      <span>
                        <h3 className="modal__apply__label">
                          Backup Collection Account
                        </h3>
                        <select
                          required
                          name="backupCollectionAccount"
                          id="backupCollectionAccount"
                          value={formData.backupCollectionAccount}
                          onChange={handleChange}
                          className="modal__apply__input"
                        >
                          <option value={null}>--</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </span>
                      <span>
                        <h3 className="modal__apply__label">
                          Complete Guarantor
                        </h3>
                        <select
                          required
                          name="completeGuarantor"
                          id="completeGuarantor"
                          value={formData.completeGuarantor}
                          onChange={handleChange}
                          className="modal__apply__input"
                        >
                          <option value={null}>--</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </span>
                    </div>
                  </div>
                )}

                {incomeType === "selfEmployed" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "700",
                        margin: "10px 0",
                        color: "#046307",
                      }}
                    >
                      Entrepreneur/Self Employed details
                    </h3>
                    <div className="modal__apply__content__input__wrap">
                      <span>
                        <h3 className="modal__apply__label">Business Type</h3>
                        <input
                          required
                          name="businessType"
                          placeholder="Business Type"
                          value={formData.businessType}
                          onChange={handleChange}
                          disabled={formStatus.submitting}
                        />
                      </span>
                      <span>
                        <h3 className="modal__apply__label">
                          CAC Business Lookup
                        </h3>
                        <input
                          required
                          name="cacBusinessLookup"
                          placeholder="CAC Business Lookup"
                          value={formData.cacBusinessLookup}
                          onChange={handleChange}
                          disabled={formStatus.submitting}
                        />
                      </span>
                      <span>
                        <h3 className="modal__apply__label">Turnover</h3>
                        <input
                          required
                          name="turnover"
                          placeholder="Turnover"
                          value={formData.turnover}
                          onChange={handleChange}
                          disabled={formStatus.submitting}
                        />
                      </span>
                      <span>
                        <h3 className="modal__apply__label">
                          Link Business Account
                        </h3>
                        <select
                          required
                          name="linkBusinessAccount"
                          id="linkBusinessAccount"
                          value={formData.linkBusinessAccount}
                          onChange={handleChange}
                          className="modal__apply__input"
                        >
                          <option value={null}>--</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </span>
                    </div>
                  </div>
                )}

                <button type="submit">Save changes</button>
              </form>
            </section>
          )}
          {select.select3 && (
            <section className="kyc__body__section__two">
              <h3>Kin Details</h3>
              <form className="kyc__form" onSubmit={handleSubmit}>
                <div className="modal__apply__content__input__wrap">
                  <span>
                    <label className="modal__apply__label">Full Name</label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Full Name"
                      value={formData.fullName}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">Phone Number</label>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <h3 className="modal__apply__label">Gender</h3>
                    <select
                      required
                      name="id"
                      id="id"
                      value={formData.id}
                      onChange={handleChange}
                      className="modal__apply__input"
                    >
                      <option value={null}>--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>

                      <option value="others">others</option>
                    </select>
                  </span>
                </div>
              </form>

              <h3>Location Details</h3>
              <form className="kyc__form" onSubmit={handleSubmit}>
                <div className="modal__apply__content__input__wrap">
                  <span>
                    <h3 className="modal__apply__label">Next of Kin State</h3>
                    <select
                      required
                      name="state"
                      id="state"
                      value={formData.state}
                      onChange={handleChange}
                      className="modal__apply__input"
                    >
                      <option value={null}>--</option>
                      <option value="Abia">Abia</option>
                      <option value="Adamawa">Adamawa</option>
                      <option value="Akwa Ibom">Akwa Ibom</option>
                      <option value="Anambra">Anambra</option>
                      <option value="Bauchi">Bauchi</option>
                      <option value="Bayelsa">Bayelsa</option>
                      <option value="Benue">Benue</option>
                      <option value="Borno">Borno</option>
                      <option value="Cross River">Cross River</option>
                      <option value="Delta">Delta</option>
                      <option value="Ebonyi">Ebonyi</option>
                      <option value="Edo">Edo</option>
                      <option value="Ekiti">Ekiti</option>
                      <option value="Enugu">Enugu</option>
                      <option value="Gombe">Gombe</option>
                      <option value="Imo">Imo</option>
                      <option value="Jigawa">Jigawa</option>
                      <option value="Kaduna">Kaduna</option>
                      <option value="Kano">Kano</option>
                      <option value="Katsina">Katsina</option>
                      <option value="Kebbi">Kebbi</option>
                      <option value="Kogi">Kogi</option>
                      <option value="Kwara">Kwara</option>
                      <option value="Lagos">Lagos</option>
                      <option value="Nasarawa">Nasarawa</option>
                      <option value="Niger">Niger</option>
                      <option value="Ogun">Ogun</option>
                      <option value="Ondo">Ondo</option>
                      <option value="Osun">Osun</option>
                      <option value="Oyo">Oyo</option>
                      <option value="Plateau">Plateau</option>
                      <option value="Rivers">Rivers</option>
                      <option value="Sokoto">Sokoto</option>
                      <option value="Taraba">Taraba</option>
                      <option value="Yobe">Yobe</option>
                      <option value="Zamfara">Zamfara</option>
                      <option value="FCT">FCT</option>
                    </select>
                  </span>

                  <span>
                    <label className="modal__apply__label">
                      Next of Kin City
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="City"
                      value={formData.lastName}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                  <span>
                    <label className="modal__apply__label">
                      Next of Kin Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={formData.lastName}
                      onChange={handleChange}
                      disabled={formStatus.submitting}
                    />
                  </span>
                </div>

                <button type="submit">Save changes</button>
              </form>
            </section>
          )}
        </div>
      </div>
    </>
  );
}

export default KYC;
