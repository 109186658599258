import { useNavigate } from "react-router-dom";
import "./signup.css";
import { useState } from "react";
import logo from "../../assets/icons/OIP.jpeg";

function Signup() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call backend API to authenticate user
    console.log(
      "User signed in with email: ",
      email,
      " and password: ",
      password
    );
  };

  return (
    <div className="signin__main">
      <form className="signin__form" onSubmit={handleSubmit}>
        <img
          src={logo}
          alt="logo"
          className="signin__form__logo"
          onClick={() => navigate("/")}
        />
        <input
          type="txt"
          placeholder="First Name"
          name="firstName"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          required
        />
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          required
        />
        <input
          type="email"
          placeholder="Email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        <input
          type="date"
          placeholder="Date of Birth"
          name="dateOfBirth"
          onChange={(e) => setDob(e.target.value)}
          value={dob}
          required
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        <button type="submit">Register</button>
        <p>
          Have an account? <b onClick={() => navigate("/signin")}>Log in</b>
        </p>
      </form>
    </div>
  );
}

export default Signup;
