import { useState } from "react";
import Navbar from "../../../components/layout/navbar/Navbar";
import "./apply.css";
import { useNavigate } from "react-router-dom";

function Apply() {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    amount: "",
    duration: "",
    interest: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call backend API to authenticate user
    console.log("Form data submitted: ", formData);
    navigate("/user/successful-application");
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <>
      <div className="apply">
        <div className="more_header">
          <h1>Loan application</h1>
        </div>
        <div className="apply__body">
          <div className="apply__steps">
            <div
              className={`apply__step ${
                step === 1 || step === 2 || step === 3
                  ? "apply__step--active"
                  : ""
              }`}
            >
              1
            </div>
            <div
              className={`apply__step ${
                step === 2 || step === 3 ? "apply__step--active" : ""
              }`}
            >
              2
            </div>
            <div
              className={`apply__step ${
                step === 3 ? "apply__step--active" : ""
              }`}
            >
              3
            </div>
          </div>

          <form onSubmit={handleSubmit} className="apply__form">
            {step === 1 && (
              <>
                <div className="apply__form-group">
                  <label htmlFor="name" className="apply__label">
                    First name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="apply__input"
                    required
                  />
                </div>
                <div className="apply__form-group">
                  <label htmlFor="name" className="apply__label">
                    Last name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="apply__input"
                    required
                  />
                </div>
                {/* <div className="apply__form-group">
                  <label htmlFor="email" className="apply__label">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="apply__input"
                    required
                  />
                </div> */}
                <div className="apply__form-group">
                  <label htmlFor="phone" className="apply__label">
                    Phone number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="apply__input"
                    required
                  />
                </div>
                <div className="apply__form-group">
                  <label htmlFor="amount" className="apply__label">
                    Loan amount
                  </label>
                  <input
                    type="number"
                    id="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="apply__input"
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={nextStep}
                  className="apply__button"
                >
                  Next
                </button>
              </>
            )}
            {step === 2 && (
              <>
                <div className="apply__form-group">
                  <label htmlFor="phone" className="apply__label">
                    Bank Verification Number(BVN)
                  </label>
                  <input
                    type="number"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="apply__input"
                    required
                  />
                </div>
                <div className="apply__form-group">
                  <label htmlFor="amount" className="apply__label">
                    National Identification Number
                  </label>
                  <input
                    type="number"
                    id="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="apply__input"
                    required
                  />
                </div>
                <select
                  className="apply__input"
                  style={{ marginBottom: "20px" }}
                >
                  <option value="">Income type</option>
                  <option value="10">Enterpreneur </option>
                  <option value="12">Self Employed</option>
                  <option value="15">Salary Earner</option>
                </select>
                <div className="apply__buttons">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="apply__button apply__button--back"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={nextStep}
                    className="apply__button"
                  >
                    Next
                  </button>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <select
                  className="apply__input"
                  style={{ marginBottom: "20px" }}
                >
                  <option value="">Duration</option>
                  <option value="10">6 Months </option>
                  <option value="12">1 year</option>
                  <option value="15">2 years</option>
                  <option value="15">3 years</option>
                  <option value="15">5 years</option>
                  <option value="15">10 years</option>
                </select>
                <div className="apply__form-group">
                  <label htmlFor="interest" className="apply__label">
                    Interest rate
                  </label>
                  <input
                    type="number"
                    id="interest"
                    value={formData.interest}
                    onChange={handleChange}
                    className="apply__input"
                    // required
                  />
                </div>
                <div className="apply__buttons">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="apply__button apply__button--back"
                  >
                    Back
                  </button>
                  <button type="submit" className="apply__button submit">
                    Submit
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      <Navbar />
    </>
  );
}

export default Apply;
